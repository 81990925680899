#languageSwitcher {
  position: fixed;
  top: 2%;
  right: 2%;
  z-index: 99;
  font-size: 2vw;

  @media screen and (max-width: 768px) {
    font-size: 5vw;
  }
}

.button__player {
  z-index: 99;
  position: fixed;
  top: 2%;
  right: 5%;
  font-size: 2vw;

  color: var(--secondcolor);
  box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);
  border-radius: var(--borderRadius);
  display: inline-block;
  padding: 0.7vw;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  cursor: pointer;
  display: none;

  &:hover {
    transition: 0.5s;
    color: var(--maincolor);
    -webkit-filter: none;
    filter: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 5vw;
    right: 10%;
    padding: 2vw;
    -webkit-filter: none;
    filter: none;
  }
}

.navi {
  color: var(--secondcolor);
  box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);
  border-radius: var(--borderRadius);
  display: inline-block;
  padding: 0.7vw;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: 2vw;
    -webkit-filter: none;
    filter: none;
  }

  &:hover {
    transition: 0.5s;
    color: var(--maincolor);
    -webkit-filter: none;
    filter: none;
  }
}

.button__fleshtv {
  position: fixed;
  top: 3%;
  left: 3%;

  font-size: 4vw;

  @media screen and (max-width: 768px) {
    font-size: 7vw;
  }
}

.button__about {
  position: fixed;
  bottom: 2%;
  right: 2%;

  font-size: 2.5vw;

  @media screen and (max-width: 768px) {
    font-size: 6vw;
  }
}

/* #buttons_top_container {
  position: fixed;

  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  z-index: 99;
  gap: 10px;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 2vw;

 
} */

.button__close {
  position: fixed;
  bottom: 2%;
  left: 2%;
  opacity: 0;
  visibility: hidden;
  font-size: 2vw;

  @media screen and (max-width: 768px) {
    font-size: 6vw;
  }
}

.button__back {
  position: fixed;
  bottom: 2%;
  left: 2%;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
  font-size: 2vw;

  @media screen and (max-width: 768px) {
    //transform: rotate(90deg);
    font-size: 6vw;
  }
}

.button__buy {
  position: fixed;
  bottom: 2%;
  right: 2%;
  opacity: 1;
  visibility: visible;
  z-index: 11;
  font-size: 2vw;

  @media screen and (max-width: 768px) {
    // transform: rotate(90deg);
    font-size: 6vw;
  }
}

/* .button__lang {
  color: var(--secondcolor);
  box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);
  border-radius: var(--borderRadius);

  padding: 1vw;
  cursor: pointer;

  -webkit-filter: blur(1px);
  filter: blur(1px);


  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 99;

  &:hover {
    transition: 0.5s;
    color: var(--maincolor);
    -webkit-filter: none;
    filter: none;
  }
}

.button__player {
  color: var(--secondcolor);
  box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);
  border-radius: var(--borderRadius);

  padding: 1vw;
  cursor: pointer;

  -webkit-filter: blur(1px);
  filter: blur(1px);

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  span {
    display: none;
  }

  &:hover {
    transition: 0.5s;
    color: var(--maincolor);
    -webkit-filter: none;
    filter: none;
  }
} */
