/* about  */

.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paypal__logo {
  width: 10%;
  -webkit-filter: blur(1px) grayscale(100%);
  filter: blur(1px) grayscale(100%);
  cursor: pointer;

  &:hover {
    -webkit-filter: none;
    filter: none;
    transition: 0.5s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    width: 25%;
    -webkit-filter: none;
    filter: none;
  }
}
