/*   classes */

.bigger {
  font-size: 4vw;
}

.font__title {
  font-family: "Titillium Web", sans-serif;
}
.backdrop__pictures {
  backdrop-filter: var(--backdropfilterblur) grayscale(40%);
  -webkit-backdrop-filter: var(--backdropfilterblur) grayscale(40%);
  padding: 20px;

  border-radius: var(--borderRadius);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    backdrop-filter: var(--backdropfilterblur) grayscale(40%);
    -webkit-backdrop-filter: var(--backdropfilterblur) grayscale(40%);
  }

  .backdrop__bio {
    backdrop-filter: var(--backdropfilterblur) grayscale(40%);
    -webkit-backdrop-filter: var(--backdropfilterblur) grayscale(40%);
  }
}

/* fallback for Firefox not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    background-color: rgba(36, 36, 36, 0.25);
  }

  .backdrop__bio {
    background-color: rgba(36, 36, 36, 0.5);
  }
}

@media screen and (max-width: 768px) {
  .center__horizontal {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centerfixed {
  position:absolute;
  right: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
} 

.center_horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.shadow {
  box-shadow: 24px 16px 64px 0 rgba(78, 78, 78, 0.1);
}

.perspective {
  transform: translate(-50%, -50%) perspective(1000px) rotateX(4deg)
    rotateY(16deg) rotateZ(-4deg);
}

.striked {
  text-decoration: line-through;
}

.hoverstriked {
  text-decoration: line-through;
  transition: all 0.5s ease-in-out;
}



@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    background-color: rgba(220, 220, 220, 0.154);
  }
}
 */
