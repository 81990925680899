    /* animations */

    .animate-fadeout {
        -webkit-animation: fadeOut 5s;
                animation: fadeOut 5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
      }
      
      @-webkit-keyframes fadeOut {
        100% {
          opacity: 0;
          visibility: hidden;
        }
      }
      
      @keyframes fadeOut {
        100% {
          opacity: 0;
          visibility: hidden;
        }
      }
  
      .animate-flicker {
        -webkit-animation: flickerAnimation ease-in-out 5s infinite;
        animation: flickerAnimation ease-in-out 5s infinite;
      }
  
      @keyframes flicker {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-webkit-keyframes flicker {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      
      .animate-opening {
        transition-duration: 0.7s;
        -webkit-animation-name: imgopening;
        animation-name: imgopening;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
  
      @-webkit-keyframes imgopening {
        from {
          opacity: 0;
        }
      
        to {
          opacity: 1;
        }
      }
      
      @keyframes imgopening {
        from {
          opacity: 0;
        }
      
        to {
          opacity: 1;
          
        }
      }
  
      /* .fade-in {
        transition: 5s;
       
        -webkit-animation-name: fadeInDisplay;
        animation-name: fadeInDisplay;
        -webkit-animation-duration: 5s;
        animation-duration: 5s;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
      }
  
      @-webkit-keyframes fadeInDisplay {
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      
      @keyframes fadeInDisplay {
        100% {
          opacity: 1;
          visibility: visible;
        }
      } */
  
      .fade-in {
          animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
        }
    
        @-webkit-keyframes fadeIn {
          0% {opacity:0;}
          100% {opacity:1;}
        }
        
        @keyframes fadeIn {
          0% {opacity:0;}
          100% {opacity:1;}
        }
  
  
      .animationblur {
        animation: blur 10s ease 0s infinite;
        -webkit-animation: blur 10s ease 0s infinite;
        -moz-animation: blur 10s ease 0s infinite;
         -ms-transform: blur 10s ease 0s infinite;
        -o-transform: blur 10s ease 0s infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
      
      }
  
      @keyframes blur {
        0% {
          -webkit-filter: blur(50px);
          -moz-filter: blur(50px);
          -o-filter: blur(50px);
          -ms-filter: blur(50px);
        }
          100% {
          -webkit-filter: blur(0px);
          -moz-filter: blur(0px);
          -o-filter: blur(0px);
          -ms-filter: blur(0px);
        }
      }


      /*  fade in main sections  */
 
   /*  main {
        animation: 1s ease-in-out 0s 1 fadeIn; 
       } */
      
      @keyframes fadeIn {
       0% {
        opacity: 0;
       }
      
       100% {
          opacity: 1;
       }
      }



.fadeout {
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  .fadein {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes fadeIn {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes fadeIn {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }