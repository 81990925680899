.donation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* img {
    margin-top: 20px;
    cursor: pointer;
    width: 20%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: var(--transition);
    opacity: 0.8;

    &:hover {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      opacity: 1;
    }
  } */
}
