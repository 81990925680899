.credits {
  padding: 1vw;

  display: flex;
  font-size: 1.5vw;
  box-shadow: var(--boxshadow);
  color: var(--secondcolor);
  border-radius: var(--borderRadius);
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
  margin-top: 5%;
  width: 100%;
  /*   width: fit-content; */

  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 2vw;
    font-size: 4vw;
    width: 100%;
    margin-bottom: 3%;
  }
}
