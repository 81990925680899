main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.about__container,
.compilation__container {
  top: 7%;
  width: 75%;

  z-index: 10;
  position: absolute;

  transition: var(--transition);

  /*   opacity: 0;
  visibility: hidden; */

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.tracks__container {
  margin-top: 5%;
  margin-bottom: 5%;
}

.artist__container {
  display: flex;
  font-size: 1.5vw;
  box-shadow: var(--boxshadow);
  color: var(--secondcolor);
  border-radius: var(--borderRadius);
  padding: 1.3vw;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 5vw;
    display: flex;
    flex-direction: column;
    text-align: center;

    .track {
      padding-bottom: 10px;
      font-size: 5vw;

      span {
        font-size: 7vw;
      }

      span::after {
        content: "\A";
        white-space: pre;
      }
    }

    .asso {
      font-size: 4vw;
    }
  }

  &:hover {
    -webkit-backdrop-filter: var(--backdropfilterblur) grayscale(0%);
    backdrop-filter: var(--backdropfilterblur) grayscale(0%);
    transition: var(--transition);
    /* transform: scale(1.005);
       */
  }
}
