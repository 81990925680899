.speech-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 2%;
}

button {
  color: var(--secondcolor);
  box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);

  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;

  border-radius: var(--borderRadius);

  cursor: pointer;
  border: 1px;
  margin-right: 10px;
  transition: var(--transition);

  @media screen and (max-width: 768px) {
    padding: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }

  &:hover {
    /*     opacity: 0.7; */

    color: var(--maincolor);
  }
}

mark {
  color: var(--maincolor);
  background-color: transparent;
  /* font-family: "Roboto", sans-serif; */
}
