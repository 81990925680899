.usbstick {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  -webkit-filter: grayscale(100) blur(2px);
  filter: grayscale(100) blur(2px);

  &:hover {
    transition: 1s;
    -webkit-filter: none;
    filter: none;
  }
}

.picture__background {
  position: fixed;
  width: 100%;
  z-index: 0;
}

.logo {
  width: auto;
  height: auto;
  max-width: 150px;
  /*   left: 50%;
  transform: translateX(-50%); */
  position: relative;
  -webkit-filter: grayscale(100) blur(0px) brightness(0.7);
  filter: grayscale(100) blur(0px) brightness(0.5);
  transition: var(--transition);
}

.picture {
  width: auto;
  height: auto;
  max-width: 75vw;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  -webkit-filter: grayscale(100) blur(0px) brightness(0.5);
  filter: grayscale(100) blur(0px) brightness(0.5);
  transition: var(--transition);
  //padding: 50px 0px;

  @media screen and (max-width: 768px) {
    max-width: 100vw;
    filter: none;
  }

  &:hover {
    -webkit-filter: none;
    filter: none;
  }
}

//////// pictures hover function

/* CSS */
.image-container {
  position: relative;
  width: 300px; /* Set to your desired image width */
  height: 200px; /* Set to your desired image height */
}

.image-container img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.top-image {
  opacity: 1;
}

.bottom-image {
  opacity: 0;
}
