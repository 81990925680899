canvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  /*   background: var(--maincolor);
  background: radial-gradient(
    circle,
    var(--maincolor) 0%,
    rgb(88, 88, 88) 100%
  ); */
  z-index: 1;
  cursor: grab;
  cursor: -webkit-grab;
}

/* styles.css */
/* .fade-in {
  opacity: 0;
  transition: opacity 2s ease-in; 
}

.fade-in.loaded {
  opacity: 1;
}
 */
