.intro,
.about__text {
  padding: 5%;
  box-shadow: var(--boxshadow);
  border-radius: var(--borderRadius);
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: "Roboto", sans-serif;
  //font-family: "Titillium Web", sans-serif;

  p {
    font-size: 1.5vw;
    width: 65%;

    margin-left: auto;
    margin-right: auto;
    color: var(--secondcolor);
    transition: var(--transition);
    //font-style: italic;
    padding-bottom: 15px;

    /*   &:hover {
      color: var(--maincolor);
    } */

    @media screen and (max-width: 768px) {
      width: 85%;
      font-size: 4vw;
      padding-bottom: 25px;
    }
  }

  p:not(.center__text) {
    text-align: justify;
  }
}

p.italic {
  font-size: 1.5vw;
  width: 50%;

  color: var(--secondcolor);
  transition: var(--transition);
  font-style: italic;

  @media screen and (max-width: 768px) {
    width: 70%;
    font-size: 4vw;
  }
}

p.text__small {
  font-size: 1.25vw;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
}

.center__text {
  text-align: center;
}

.larger__text {
  font-size: 3vw;

  @media screen and (max-width: 768px) {
    font-size: 8vw;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--secondcolor);
  color: var(--secondcolor);
  overflow: visible;
  text-align: center;
  height: 5px;
  opacity: 0.5;
}

/* hr:after {
  background: #fff;
  content: '';
  padding: 0 4px;
  position: relative;
  top: -13px;
}
 */
