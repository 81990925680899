.artist__bio {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--secondcolor);
  padding: 1.3vw;
  width: 100%;
  height: 100%;
  z-index: 12;
  padding: 5%;
  font-size: 1.5vw;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
    overflow-y: scroll;
  }

  a {
    font-size: 1.3vw;

    @media screen and (max-width: 768px) {
      font-size: 3vw;
    }
  }

  h1 {
    font-size: 6vw;
    /*  position: absolute; */

    position: fixed;
    top: 2%;
    left: 2%;

    @media screen and (max-width: 768px) {
      font-size: 8vw;
      max-width: 75vw;
      /*   position: fixed;
      bottom: 2%;
      left: 2%; */
    }
  }

  .text {
    font-family: "Roboto", sans-serif;
    margin-left: 25%;
    position: fixed;
    // margin-right: 5%;
    bottom: 2%;
    right: 2%;
    width: 50vw;

    text-align: right;

    box-shadow: 0px 0px 100px 0px rgba(252, 76, 41, 0.26);
    border-radius: var(--borderRadius);
    backdrop-filter: blur(5px);
    display: inline-block;
    padding: 2vw;

    @media screen and (max-width: 768px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      height: fit-content;
      width: 90vw;
      padding: 4vw;
      text-align: left;
    }
  }

  .artist__picture {
    max-height: 80vh;
    max-width: 80vw;
    width: auto;
    height: auto;
    box-shadow: var(--boxshadow);
    transition: var(--transition);

    &:hover {
      filter: grayscale(100) /* brightness(1.5) */;
    }

    /*   @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      max-width: unset;
      height: 100vh;
    } */

    @media screen and (max-width: 768px) {
      position: fixed;
      top: 0; /* Added to align top */

      left: 50%;
      transform: translate(-50%, 0);
      width: auto; /* Full viewport width */
      height: 100vh; /* Full viewport height */
      max-height: none;
      max-width: none;
      margin: auto; /* Center the image */
      box-shadow: none; /* Optional: remove the shadow if not needed for full-screen images */
    }
  }
}

.oxhy__text,
.thaa__text,
.jonas__text,
.barking__text,
.loreles__text,
.colin__text,
.cedrik__text,
.felicity__text,
.dave__text,
.arash__text,
.german__text,
.robbie__text,
.merzbow__text,
.carl__text,
.neurokill__text,
.rym__text,
.sukitoa__text,
.dylan__text,
.helene__text,
.agf__text {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.container__picture {
  position: relative;
}

.credit__photo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 1.3vw;
  writing-mode: tb-rl;
}

/* @media screen and (max-width: 768px) {
  .text {
    width: 75vw;
  }

  .artist__bio {
    font-size: 4vw;
    overflow-y: scroll;

    img {
      max-width: 85vw;
    }

    p {
      max-width: 70%;
      left: auto;
      bottom: auto;
      font-size: 8vw;
    }

    a {
      font-size: 3vw;
    }
  }
}
 */
