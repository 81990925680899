.compilation__title,
.about__title {
  font-family: "Titillium Web", sans-serif;
  position: fixed;
  font-size: 10vw;
  color: rgba(112, 128, 144, 0.534);
  //color: transparent;
  /*  z-index: 100; before*/
  z-index: 49;
  top: 1%;
  left: 1%;
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 15vw;
    // transform: rotate(90deg);
    // transform-origin: top left;
  }
}
