/* base */

@use "./base/reset";
@use "./base/base";
@use "./base/classes";
@use "./base/cursor";

/* abstract */

@use "./abstract/loader";
@use "./abstract/animations";

/* components */

@use "./components/webgl";

@use "./components/switch-button";

@use "./components/buttons";
@use "./components/menu";
@use "./components/titles";
@use "./components/text";
@use "./components/credits";

@use "./components/donation";

@use "./components/containers";
@use "./components/player";
@use "./components/tracks";

@use "./components/bio-section";
@use "./components/pictures";
@use "./components/buttons-speech";
@use "./components/paypal-container";

@use "./components/other";

@use "./components/language-switcher";

/* pages */

.pp-S4GPESJBLZYZU {
  text-align: center;
  border: none;
  border-radius: 1.5rem;
  min-width: 11.625rem;
  padding: 0 2rem;
  height: 2.625rem;
  font-weight: bold;
  background-color: #ffd140;
  color: #000000;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  cursor: pointer;
}
