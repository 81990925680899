.track {
  cursor: pointer;
  span {
    font-weight: bold;
  }

  &:hover {
    color: var(--maincolor);
    transition: all 0.5s ease-in-out;
  }
}
