/* * {
  cursor: none;
} */

.cursor {
  position: fixed;
  mix-blend-mode: luminosity;
  top: 0;
  left: 0;
  z-index: 10000;
  pointer-events: none;

  circle {
    fill: rgba(181, 181, 181, 0.533);
    z-index: 10000;
    transform-origin: 50% 50%;
    transform: scale(0.5);
    transition: transform 0.5s;
  }
}

@media screen and (max-width: 768px) {
  .cursor {
    display: none;
  }
}
