.loader {
  background: var(--maincolor);
  background: radial-gradient(
    circle,
    var(--maincolor) 0%,
    rgb(88, 88, 88) 100%
  );

  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  transition-timing-function: ease-in-out;

  animation: fadeOut 1s ease-in-out 1.5s forwards; // JUST CSS
}

.sub-loader {
  background: rgb(88, 88, 88);
  width: 100%;
  height: 100%;
  position: fixed;
  transition-timing-function: ease-in-out;
}

.textloader {
  font-family: "Titillium Web", sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
  color: var(--maincolor);

  @media screen and (max-width: 768px) {
    font-size: 6vw;
  }
}

.loader.hidden {
  -webkit-animation: fadeOut 3s;
  animation: fadeOut 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flicker-loader {
  -webkit-animation: flickerAnimation ease-in-out 5s infinite;
  animation: flickerAnimation ease-in-out 5s infinite;
}
