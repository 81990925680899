div#paypal-container-ZKNEGQMRLREBA {
  p.desc-label {
    display: none;
  }

  select#selector {
    background: transparent;
    color: var(--secondcolor);
    text-align: center;
  }

  div#paypal-form-fields-container-ZKNEGQMRLREBA {
    margin: 0;
  }
}
